import swal from 'sweetalert2';
import Utilities from '../../Utilities';

require('iframe-resizer');

const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');
const creativeAssetDetailTypes = ['cad_ty', 'cad_cs', 'cad_len', 'cad_ud', 'cad_cpe', 'cad_cta', 'cad_cre', 'cad_img', 'cad_rt', 'cad_vs', 'cad_vl', 'cad_vde', 'cad_mel'];
const multiSelectCadTypes = ['cad_cpe', 'cad_cre', 'cad_vde', 'cad_mel'];
// const copyAssetOptions = ['cad_cs', 'cad_len', 'cad_ud', 'cad_cpe'];
const graphicAndMotionAssetOptions = ['cad_ty', 'cad_cta', 'cad_img', 'cad_cre', 'cad_rt', 'cad_vs', 'cad_vl', 'cad_mel'];
const videoAssetOptions = ['cad_ty', 'cad_cta', 'cad_img', 'cad_cre', 'cad_vs', 'cad_vl', 'cad_rt'];

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Return Klaviyo Email Templates
 *
 * @returns {Promise<void>}
 */
function refreshEmailTemplates() {
  // Disable button
  $('#creative-submit').prop('disabled', true);
  $('#creative-submit').attr('title', 'Fetching email templates');
  $('.loader, #loading-overlay').show();

  // Pull templates
  $.ajax({
    url: `/creativereview/creative-asset/get/process?project_id=${projectID}&campaign_id=${campaignID}&asset_type=email`,
    type: 'GET',
  })
    .done((data) => {
      const { emailTemplates, error } = JSON.parse(data);

      if (error === true) {
        $('#creative-submit').attr('title', 'Failed to pull email templates');
        $('.loader, #loading-overlay').hide();
        swal.fire({
          icon: 'error',
          title: 'Failed to Pull Email Templates',
          text: 'We could not load your email templates from Klaviyo, please ensure your connection is properly configured in the Connection tool.',
          timer: 5000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        });
      } else {
        const templateSelectOptions = Object.keys(emailTemplates).map((templateID) => {
          const { name } = emailTemplates[templateID];
          return `<option value="${templateID}">${name}</option>`;
        });

        $('#email_template_id').html(templateSelectOptions.join('')).selectpicker('refresh');

        $('#creative-submit').prop('disabled', false);
        $('#creative-submit').removeAttr('title');
        $('.loader, #loading-overlay').hide();
      }
    })
    .fail((jqXHR, status, error) => {
      $('#creative-submit').attr('title', 'Failed to pull email templates');
      $('.loader, #loading-overlay').hide();
      swal.fire({
        icon: 'error',
        title: 'Failed to Pull Email Templates',
        text: 'We could not load your email templates from Klaviyo, please ensure your connection is properly configured in the Connection tool.',
        timer: 5000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      });

      console.log(jqXHR);
      console.log(status);
      console.log(error);
    });
}

/**
 * Submit the creative form.
 *
 * @param checkIG
 */
function submitCreative(checkIG = '') {
  $('.loader, #loading-overlay').show();
  $('#creative-error-alert').hide();
  $('#creative-success-alert').hide();
  $('#creative-submit').addClass('btn-waiting').prop('disabled', true);
  const creativeId = $('#creative_id').val();
  const formData = new FormData(document.getElementById('creative-form'));

  // Creative Asset Details
  creativeAssetDetailTypes.forEach((cadType) => {
    formData.append(cadType, $(`#${cadType}_select`).val());
  });

  $.ajax({
    url: `/campaign/edit/creative/process?project_id=${projectID}&campaign=${campaignID}&creative=${creativeId}&checkIG=${checkIG}`,
    type: 'POST',
    data: formData,
    dataType: 'json',
    contentType: false,
    processData: false,
  })
    .done((data) => {
      const { success, message } = data;
      $('#creative-submit').removeClass('btn-waiting').prop('disabled', false);
      if (success) {
        if ($('#creative-new').val() === '1') {
          if (message) {
            swal.fire({
              title: message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              confirmButtonText: 'Ok',
            }).then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        } else {
          $('.loader, #loading-overlay').hide();

          $('#creative-success-alert').slideDown(100);
          $(`#creative-name-${creativeId}`).html(`${$('#creative_label').val()} - ${$('#creative_name').val()}`);

          if ($('#use-in-builds').val() === '1') {
            $(`#creativelink-${creativeId} span`).remove('.status-dot');
          } else {
            $(`#creativelink-${creativeId} span:first-child`).prepend('<span class="status-dot inactive" title="Do not use in Builds!"></span>');
          }

          if ($('select[name=creative_status] option:selected').val() === '1') {
            $(`#creative-disabled-${creativeId}`).hide();
          } else {
            $(`#creative-disabled-${creativeId}`).show();
          }
        }
      } else {
        $('.loader, #loading-overlay').hide();
        if (message === 'Use IG') {
          swal.fire({
            title: 'Use an Instagram Account on Ads',
            text: 'Your ad appears to be using a Page Backed Instagram account. This is not recommended as comments will not be associated with the Primary Instagram account. Please update your ad to use an Instagram account before adding it.',
            icon: 'error',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
            confirmButtonText: 'I want to use a Page Backed Account',
            cancelButtonText: 'I will fix this',
          }).then((result) => {
            if (result.value) {
              // Submit creative
              submitCreative();
            } else {
              swal.fire({
                title: 'Your Creative was NOT Added to the Jungle',
                text: 'Once you update your Facebook ad to use an Instagram Account then please add it to the Jungle again.',
                icon: 'warning',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                confirmButtonText: 'Ok',
              }).then();
            }
          });
        } else {
          $('#creative-error-body').html(data.message);
          $('#creative-error-alert').slideDown(100);
        }
      }
    })
    .fail((jqXHR, status, error) => {
      $('#creative-submit').removeClass('btn-waiting').prop('disabled', false);
      $('#creative-error-body').html(error);
      $('#creative-error-alert').slideDown(100);
    });
}

export const init = () => {
  $(() => {
    const isEmailCampaign = (parseInt($('#campaign_type').val(), 10) === 4);

    /**
     *
     * INITIAL LOAD EVENTS
     *
     */

    $('#creatives .sortable').sortable({ placeholderClass: 'list-group-item clearfix creativelink' }).bind('sortupdate', () => {
      const sortOrder = [];
      $('.sortable a').each(function processSortable() {
        sortOrder.push($(this).data('creative'));
      });
      const sortOrderString = sortOrder.join(',');

      $.ajax({
        url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}`, type: 'get', data: { sortcreatives: sortOrderString }, dataType: 'json',
      })
        .done((data) => {
          if (!data.success) {
            $('#creative-error-main-body').html(data.message);
            $('#creative-error-main-alert').fadeTo(2000, 500).slideDown(100, () => {
              $('#creative-error-main-alert').slideUp(500);
            });
          }
        })
        .fail((jqXHR, status, error) => {
          $('#creative-error-main-body').html(error);
          $('#creative-error-main-alert').fadeTo(2000, 500).slideDown(100, () => {
            $('#creative-error-main-alert').slideUp(500);
          });
        });
    });

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */
    $('.copyCreative').on('click', (event) => {
      event.stopPropagation();
      event.preventDefault();

      const {
        creativeid, creativename, adsetid, mty, catalogid,
      } = event.currentTarget.dataset;

      $('#copy_creative_adset option').prop('disabled', false);
      $(`#copy_creative_adset [value*="${adsetid}"]`).prop('disabled', true);
      $('#copy_creative_adset, #copy_creative_label').val('');
      $('#copy_creative_adset').selectpicker('refresh');

      if (mty === '014') {
        // This Story ads can only be copied to story and blended adsets
        $('#copy_creative_adset [value*="feed"]').prop('disabled', true);
        $('#copy_creative_adset [value*="reels"]').prop('disabled', true);
        $('#copy_creative_adset').selectpicker('refresh');
      } else if (mty === '152') {
        // This Reels ads can only be copied to reels and blended adsets
        $('#copy_creative_adset [value*="feed"]').prop('disabled', true);
        $('#copy_creative_adset [value*="stories"]').prop('disabled', true);
        $('#copy_creative_adset').selectpicker('refresh');
      } else {
        // Ad cannot be placed in story/reels adsets
        $('#copy_creative_adset [value*="stories"]').prop('disabled', true);
        $('#copy_creative_adset [value*="reels"]').prop('disabled', true);
        $('#copy_creative_adset').selectpicker('refresh');
      }

      if (mty === '016' || mty === '24') {
        // This Collection ads can only be copied dpa adsets with the same catalog
        $('#copy_creative_adset option').prop('disabled', true);

        if (catalogid) {
          $(`#copy_creative_adset [value*="-${catalogid}"]`).prop('disabled', false);
          $(`#copy_creative_adset [value*="${adsetid}"]`).prop('disabled', true);
        }

        $('#copy_creative_adset').selectpicker('refresh');
      }

      $('#copy_creative_name').val(creativename);
      $('#copy_creative_id').val(creativeid);

      $('#creativeModal').appendTo('body').modal('hide');
      $('#copyCreativeModal').appendTo('body').modal('show');
    });

    $('#copy_creative_adset').on('change', (e) => {
      const adSetValue = e.currentTarget.value.split('-');
      const adSetID = adSetValue[0];

      if (adSetID in window.newCreativeLabels) {
        $('#copy_creative_label').val(window.newCreativeLabels[adSetID].newCreativeLabel);
      } else {
        $('#copy_creative_label').val('');
      }
    });

    $('#copy-creative-submit').on('click', (event) => {
      event.preventDefault();
      $('.loader, #loading-overlay').show();
      const creativeId = $('#copy_creative_id').val();

      $.ajax({
        url: `/campaign/edit/creative/process?project_id=${projectID}&campaign=${campaignID}&creative=${creativeId}&copyAd=1`, type: 'get', data: $('#copy-creative-form').serialize(), dataType: 'json',
      })
        .done((data) => {
          const { success, error, message } = data;
          $('.loader, #loading-overlay').hide();
          if (success) {
            swal.fire({
              title: 'Creative has been copied successfully!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              confirmButtonText: 'Ok',
            }).then(() => {
              $('#copyCreativeModal').appendTo('body').modal('hide');
              $('.loader, #loading-overlay').show();
              window.location.reload();
            });
          } else if (error) {
            swal.fire({
              title: message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              confirmButtonText: 'Ok',
            }).then();
          }
        })
        .fail((jqXHR, status, error) => {
          swal.fire({
            title: error,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            confirmButtonText: 'Ok',
          }).then();
        });
    });

    $('[id^=creativelink]').on('click', (event) => {
      event.preventDefault();

      $('#creative-form .copyCreative').show();
      $('#creative-error-main-alert').hide();
      $('#creative-error-alert').hide();
      $('#creative-success-alert').hide();
      $('#creative-form').trigger('reset');
      $('#creative_adset').selectpicker('refresh');
      // $('#creative_adset').prop("disabled",true);
      $('#copyLexicon').collapse('hide');
      $('#assetLexicon').collapse('hide');

      Object.keys(window.creativeLexicon).forEach((key) => {
        $(`#${key}`).selectpicker('refresh');
      });

      $('#creative_status').selectpicker('refresh');
      $('#creative_previews_creative').selectpicker('refresh');
      $('#creative-new').val('0');
      $('#creative-submit').html('Update Creative');
      const creativeId = $(event.currentTarget).data('creative');
      $('#creative_id').val(creativeId);

      $.ajax({ url: `/campaign/edit/creative/get/process?project_id=${projectID}&campaign=${campaignID}&creative=${creativeId}`, dataType: 'json' })
        .done((data) => {
          const copyAdInModalButton = document.querySelector('#creative-form .copyCreative');
          copyAdInModalButton.setAttribute('data-creativeid', '');
          copyAdInModalButton.setAttribute('data-creativename', '');
          copyAdInModalButton.setAttribute('data-adsetid', '');
          copyAdInModalButton.setAttribute('data-mty', '');
          copyAdInModalButton.setAttribute('data-catalogid', '');

          if (data.id) {
            const {
              name,
              label,
              original_creative: originalCreative,
              original_copy: originalCopy,
              copy_created_by_user_id: copyCreatedByUserID,
              creative_created_by_user_id: creativeCreatedByUserID,
              facebook_ad: facebookAd,
              adset,
              removeLabelValidation,
              status,
              creative_previews: creativePreviews,
              use_in_builds: useInBuilds,
              m_ty: mTy,
              fb_catalog_id: fbCatalogID,
              subject,
              preview_lines: previewLines,
              email_template_id: templateID,
            } = data;

            copyAdInModalButton.setAttribute('data-creativeid', creativeId);
            copyAdInModalButton.setAttribute('data-creativename', name);
            copyAdInModalButton.setAttribute('data-adsetid', adset);
            copyAdInModalButton.setAttribute('data-mty', mTy);
            copyAdInModalButton.setAttribute('data-catalogid', fbCatalogID);

            if (useInBuilds === '0') {
              $('#creative-form .copyCreative').hide();
            }

            $('#creative_name').val(name);
            $('#creative_label').val(label);
            $('#creative_facebook_ad').val(facebookAd.toString());
            $('#use-in-builds').val(useInBuilds);

            if (isEmailCampaign) {
              $('#creative-form #emailTemplatePreview').empty();
              $('#creative-form #subject').val(subject);
              $('#creative-form #preview_lines').val(previewLines);
              $('#creative-form #email_template_id').val(templateID).selectpicker('refresh').trigger('change');
            }

            if (removeLabelValidation) {
              $('#creative_label').removeAttr('pattern');
            }

            const selectPickersToUpdate = [
              { id: 'original_creative', value: originalCreative },
              { id: 'original_copy', value: originalCopy },
              { id: 'copy_created_by_user_id', value: copyCreatedByUserID },
              { id: 'creative_created_by_user_id', value: creativeCreatedByUserID },
              { id: 'creative_adset', value: adset },
              { id: 'creative_status', value: status },
              { id: 'creative_previews_creative', value: creativePreviews },
            ];

            Object.keys(window.creativeLexicon).forEach((key) => {
              const dataField = key.replace(/lexicon_/g, '');

              selectPickersToUpdate.push({ id: key, value: data[dataField] });
            });

            selectPickersToUpdate.forEach((details) => {
              if ($(`#${details.id} option[value="${details.value}"`).length > 0) {
                $(`#${details.id}`).val(details.value);

                if (details.id === 'lexicon_m_ty') {
                  $(`#${details.id}`).trigger('change');
                }
              }

              $(`#${details.id}`).selectpicker('refresh');
            });

            creativeAssetDetailTypes.forEach((cadType) => {
              $(`#${cadType}_select`).val((multiSelectCadTypes.includes(cadType) && data[cadType]) ? JSON.parse(data[cadType]) : data[cadType]).selectpicker('refresh');
            });

            // $('#creative_adset').prop("disabled",true);

            $('#creativeModal').appendTo('body').modal('show');
          } else {
            $('#creative-error-main-body').html(data.message);
            $('#creative-error-main-alert').slideDown(100);
          }
        })
        .fail((jqXHR, status, error) => {
          $('#creative-error-main-body').html(error);
          $('#creative-error-main-alert').slideDown(100);
        });
    });

    $('#addnew-creative').on('click', (event) => {
      event.preventDefault();
      $('#creative-form .copyCreative').hide();
      $('#creative-error-main-alert').hide();
      $('#creative-error-alert').hide();
      $('#creative-success-alert').hide();
      $('#creative-form').trigger('reset');
      $('#creative-form #emailTemplatePreview').empty();
      $('#creative_adset').removeClass('btn-waiting').prop('disabled', false);
      $('#creative_adset').selectpicker('refresh');
      $('#creative_created_by_user_id').val('').selectpicker('refresh');
      $('#original_creative').val('').selectpicker('refresh');
      $('#copy_created_by_user_id').val('').selectpicker('refresh');
      $('#original_copy').val('').selectpicker('refresh');
      $('#copyLexicon').collapse('hide');
      $('#assetLexicon').collapse('hide');

      // Creative Asset Details
      creativeAssetDetailTypes.forEach((cadType) => {
        $(`#${cadType}_select`).val('').selectpicker('refresh');
        // $(`#${cadType}`).hide();
      });

      /* copyAssetOptions.forEach((cadType) => {
        $(`#${cadType}`).show();
      }); */

      Object.keys(window.creativeLexicon).forEach((key) => {
        $(`#${key}`).selectpicker('refresh');
      });

      $('#creative_status').val('1');
      $('#creative_status').selectpicker('refresh');
      $('#creative_previews_creative').val('yes');
      $('#creative_previews_creative').selectpicker('refresh');
      $('#creative-new').val('1');
      $('#creative_id').val('new');
      $('#creative-submit').html('Add Creative');
      $('#creativeModal').appendTo('body').modal('show');
    });

    $('#creative-form').on('submit', (event) => {
      event.preventDefault();
      submitCreative('true');
    });

    /* $('#lexicon_m_ty').on('change', (e) => {
      // NEED TO MAP OUT DIFFERENT MEDIA TYPES TO DISPLAY CORRECT ASSET DETAIL FIELDS
      const mediaType = e.currentTarget.value;

      const mediaTypes = {
        '001': 'all', // Link
        '002': 'all', // Square
        '003': 'graphic', // Long Image
        '004': 'all', // Album
        '005': 'graphic', // Carousel Images
        '006': 'video', // Carousel Videos
        '007': 'all', // Slideshow
        '008': 'video', // Link - Video
        '009': 'video', // Wide Video (Non-Link)
        '010': 'video', // Square Video
        '011': 'gif', // Gif (Just a moving image
        '012': 'all', // App Ads
        '013': 'all', // Instant Articles
        '014': 'all', // Stories
        '015': 'all', // Instant Experience
        '016': 'all', // Collection
        '017': 'all', // Lead Ads
        '018': 'all', // YouTube - Instream
        '019': 'all', // YouTube - Bumper
        '020': 'all', // YouTube - Outstream
        '021': 'all', // YouTube - Video Discovery
        '022': 'graphic', // Display - 200x200
        '023': 'graphic', // Display - 240x400
        '24': 'all', // DPA
      };

      if (mediaType in mediaTypes) {
        displayCreativeAssetDetailFields(mediaTypes[mediaType]);
      }
    }); */

    /* function displayCreativeAssetDetailFields(mediaType) {
      const videoElement = $('#cad_vde');
      const videoElementSelect = $('#cad_vde_select');
      const motionElement = $('#cad_mel');
      const motionElementSelect = $('#cad_mel_select');

      graphicAndMotionAssetOptions.forEach((cadType) => {
        $(`#${cadType}_select`).val('').selectpicker('refresh');
        $(`#${cadType}`).hide();
      });

      videoAssetOptions.forEach((cadType) => {
        $(`#${cadType}_select`).val('').selectpicker('refresh');
        $(`#${cadType}`).hide();
      });

      videoElementSelect.val('').selectpicker('refresh');
      videoElement.hide();

      motionElementSelect.val('').selectpicker('refresh');
      motionElement.hide();

      if (mediaType === 'all') {
        graphicAndMotionAssetOptions.forEach((cadType) => {
          $(`#${cadType}_select`).val('').selectpicker('refresh');
          $(`#${cadType}`).show();
        });

        videoAssetOptions.forEach((cadType) => {
          $(`#${cadType}_select`).val('').selectpicker('refresh');
          $(`#${cadType}`).show();
        });

        videoElementSelect.val('').selectpicker('refresh');
        videoElement.show();

        motionElementSelect.val('').selectpicker('refresh');
        motionElement.show();
      } else if (mediaType === 'graphic') {
        graphicAndMotionAssetOptions.forEach((cadType) => {
          $(`#${cadType}_select`).val('').selectpicker('refresh');
          $(`#${cadType}`).show();
        });

        if ($('#lexicon_m_ty').val() !== '011') {
          // Hide video fields if it isn't a GIF ad
          $('cad_vs').hide();
          $('cad_vl').hide();
          $('cad_mel').hide();
        }

        $('#cad_ty_select').val(mediaType === 'graphic' ? '01' : '03').selectpicker('refresh');

      } else {
        videoAssetOptions.forEach((cadType) => {
          $(`#${cadType}_select`).val('').selectpicker('refresh');
          $(`#${cadType}`).show();
        });

        const elementsField = mediaType === 'video' ? 'cad_vde' : 'cad_mel';

        $('#cad_ty_select').val('02').selectpicker('refresh');
        $(`#${elementsField}_select`).val('').selectpicker('refresh');
        $(`#${elementsField}`).show();
      }
    } */

    $('#cad_cpe_select, #cad_cre_select, #cad_mel_select, #cad_vde_select').on('change', (e) => {
      if (e.currentTarget.id === 'cad_cpe_select') {
        const $copyElementsField = $('#cad_cpe_select');
        if ($copyElementsField.val() && $copyElementsField.val().includes('09')) {
          $copyElementsField.val(['09']).selectpicker('refresh');
        }
      } else if (e.currentTarget.id === 'cad_cre_select') {
        const $coreElementsField = $('#cad_cre_select');
        if ($coreElementsField.val() && $coreElementsField.val().includes('07')) {
          $coreElementsField.val(['07']).selectpicker('refresh');
        }
      } else if (e.currentTarget.id === 'cad_mel_select') {
        const $motionElementsField = $('#cad_mel_select');
        if ($motionElementsField.val() && $motionElementsField.val().includes('08')) {
          $motionElementsField.val(['08']).selectpicker('refresh');
        }
      } else if (e.currentTarget.id === 'cad_vde_select') {
        const $videoElementsField = $('#cad_vde_select');
        if ($videoElementsField.val() && $videoElementsField.val().includes('10')) {
          $videoElementsField.val(['10']).selectpicker('refresh');
        }
      }
    });

    $('#creative-form #email_template_id').on('change', (e) => {
      // Render email template
      $('.loader, #loading-overlay').show();
      const templateID = e.currentTarget.value;

      $('#creative-form #emailTemplatePreview').empty().append(`<iframe id="emailPreview${templateID}" src="/email/preview?template_id=${templateID}" style="min-width: 270px; width:100%;" scrolling="no" frameBorder="0"></iframe>`);
      $(`#emailPreview${templateID}`).iFrameResize();

      $('.loader, #loading-overlay').hide();
    });

    $('#refreshEmailTemplates').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      refreshEmailTemplates();
    });

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
